






























































// import { StakingViewModel } from '../viewmodels/staking-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    ConnectMetamask: () => import('../../../components/ConnectMetamask.vue')
  }
})
export default class SenderConnectWallet extends Vue {
  // @Provide() vm = new StakingViewModel()
  walletStore = walletStore
  // destroyed() {
  // this.vm.destroy()
  // }
  networks = [
    {
      chain: 'BSC',
      chainId: '97',
      name: 'Binance Smart Chain network'
    },
    {
      chain: 'BSC',
      chainId: '97',
      name: 'Binance Smart Chain network'
    }
  ]
  selectedNetwork = this.networks[0]
}
